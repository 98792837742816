import React, {Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import SuspenseLoader from '../../../components/suspenseLoader/SuspenseLoader';

interface SuspenseLayoutProps {
  background?: string;
}

const SuspenseLayout: React.FC<SuspenseLayoutProps> = ({background}) => (
  <Suspense fallback={<SuspenseLoader background={background} />}>
    <Outlet />
  </Suspense>
);
export default SuspenseLayout;
