import classNames from 'classnames';
import React from 'react';

export interface BlackBackgroundProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
  disabled?: boolean;
}

const BlackBackground: React.FC<BlackBackgroundProps> = ({
  children,
  className,
  disabled = false,
  ...rest
}) => (
  <div
    className={classNames(
      'absolute w-full h-full ',
      !disabled && 'bg-black/50',
      className,
    )}
    {...rest}
  >
    {children}
  </div>
);

export default BlackBackground;
