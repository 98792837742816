import {useEffect} from 'react';
import {useRemoveGameAuthData} from '../services/gameAuth/AuthProvider';
import {useGameAuth} from '../services/gameAuth/GameAuthService';

const Logout: React.FC = () => {
  const logout = useRemoveGameAuthData();

  useEffect(() => {
    logout.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
export default Logout;
