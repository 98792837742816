import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {UseShortMutationOptions} from '../../../helpers/ReactQuesyOptionBuilder';
import {GlobalNotificationErrorHandler} from '../../../services/globalNotification/errorHandler';
import APIRequest from '../../Api';
import {ApiError} from '../../basicTypes/ApiError';
import {IntegrationSaveDAO} from './dao/IntegrationSaveDAO';
import {IntegrationGetSaveDAO} from './dao/SaveRequestDAO';

const SaveGameKey = 'integration/save/save';

export const useLoadGame = (
  options?: UseShortMutationOptions<
    string,
    AxiosError<ApiError>,
    IntegrationGetSaveDAO
  >,
) => {
  return useMutation<string, AxiosError<ApiError>, IntegrationGetSaveDAO>(
    [SaveGameKey],
    async (request: IntegrationGetSaveDAO): Promise<string> => {
      const {data} = await APIRequest.post<string>(
        '/Integration/GetSave',
        request,
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      retry: 0,
      ...options,
    },
  );
};

export const useSaveGame = (
  options?: UseShortMutationOptions<
    IntegrationSaveDAO,
    AxiosError<ApiError>,
    IntegrationSaveDAO
  >,
) => {
  return useMutation<
    IntegrationSaveDAO,
    AxiosError<ApiError>,
    IntegrationSaveDAO
  >(
    [SaveGameKey],
    async (newSimulation: IntegrationSaveDAO): Promise<IntegrationSaveDAO> => {
      await APIRequest.post(`/Integration/Save`, newSimulation);
      return newSimulation;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};
