import {useAnimations, useGLTF} from '@react-three/drei';
import {forwardRef, useEffect, useImperativeHandle, useRef} from 'react';
import * as THREE from 'three';
import {ModelTransform} from '../../../types/models/BasicTypes';
import {EnimyModel} from '../../../types/models/EnimyMode';

export interface EnimyRefProps {
  attack: () => void;
  getDamage: () => void;
}

const Enimy = forwardRef<EnimyRefProps, ModelTransform>(
  ({position, rotation, scale}, ref) => {
    const group = useRef<THREE.Group>() as React.MutableRefObject<THREE.Group>;
    const {nodes, materials, animations} = useGLTF(
      'https://storage.googleapis.com/stemmy-integrations/models/enimy/Dummy.glb',
    ) as unknown as EnimyModel;
    const {actions} = useAnimations(animations, group);

    useEffect(() => {
      const animation = actions.Idle;
      if (!animation) return;
      animation.play();
    }, [actions.Idle]);

    useImperativeHandle(ref, () => ({
      attack() {
        const animation = actions.Attack;
        if (!animation) return;
        animation.setLoop(THREE.LoopOnce, 1);
        animation.clampWhenFinished = true;
        animation.enabled = true;
        animation.play().reset();
      },
      getDamage() {
        const animation = actions.Damage;
        if (!animation) return;
        animation.reset();
        animation.setLoop(THREE.LoopOnce, 1);
        animation.play().reset();
      },
    }));

    return (
      <group
        ref={group}
        position={position}
        rotation={rotation}
        scale={scale}
        dispose={null}
      >
        <group name="Scene" castShadow>
          <group name="Armature">
            <primitive object={nodes.RigPelvis} />
            <skinnedMesh
              castShadow
              name="Practice_Dummy"
              geometry={nodes.Practice_Dummy001.geometry}
              material={materials.DummyMaterial}
              skeleton={nodes.Practice_Dummy001.skeleton}
            />
          </group>
        </group>
      </group>
    );
  },
);
export default Enimy;
