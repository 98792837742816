import classNames from 'classnames';
import {ButtonStyleDef} from '../../../form/button/Button';
import ButtonsList from './ButtonsList';

export enum ButtonsAlignment {
  Horizontal,
  Vertical,
  VerticalCentered,
}

export enum WidnowSize {
  Auto = 'w-auto',
  Big = 'w-3/4',
  Fullscreen = '',
}

export enum ButtonsLayout {
  VERTICAL,
  TWO_COLUMNS,
}

export interface BaseButtonProps {
  title: string;
  className?: string;
  buttonStyle?: ButtonStyleDef;
}

export interface PopupButton extends BaseButtonProps {
  id: string;

  isCoorrectAnswer?: boolean; //undefined - не задан, true - верный, false - неверный

  onClick?: (id?: string) => void;

  jumpToIndexOnClick?: number;

  isNextOnClick?: boolean;
  isBackOnClick?: boolean;
  isFinishOnClick?: boolean;
  isFallbackOnClick?: boolean;

  clickDelay?: number;
}

export interface GamePopupFillProps {
  id: string;

  innerButtons?: PopupButton[];
  externalbuttons?: PopupButton[];

  title?: string;
  text?: string;
  buttonsAlignment?: ButtonsAlignment;
  buttonsLayout?: ButtonsLayout;

  conversationImage?: string;

  horizontalElement?: React.ReactNode;
  verticalElement?: React.ReactNode;

  windowSize?: WidnowSize;

  fallback?: GamePopupFillProps;

  disableUpperPadding?: boolean;
  disableLowerPadding?: boolean;
}

export interface GamePopupElementProps extends GamePopupFillProps {
  prefixId: string;
  onBack: () => void;
  onNext: () => void;
  onFinish: () => void;
  onFallback: () => void;
  onJumpToSlide: (val: number) => void;
}

const GamePopupElement: React.FC<GamePopupElementProps> = ({
  prefixId,
  horizontalElement,
  title,
  text,
  verticalElement,
  innerButtons,
  onBack,
  onNext,
  onFinish,
  onFallback,
  onJumpToSlide,
  disableUpperPadding = false,
  disableLowerPadding = false,
  buttonsAlignment = ButtonsAlignment.Vertical,
  buttonsLayout,
}) => (
  <div className="flex gap-12 h-full">
    <div
      className={classNames(
        'flex-1 flex flex-col gap-2',
        !disableUpperPadding && 'pt-2 lg:pt-4',
        !disableLowerPadding && 'pb-2 lg:pb-4',
      )}
    >
      {title && (
        <div
          className={classNames(
            !text && !horizontalElement && 'flex-1',
            'text-game-lg font-bold',
          )}
        >
          {title}
        </div>
      )}
      {text && (
        <div
          className={classNames(
            buttonsAlignment !== ButtonsAlignment.VerticalCentered && 'flex-1',
          )}
        >
          {text}
        </div>
      )}
      {horizontalElement && <div className="flex-1">{horizontalElement}</div>}
      {innerButtons && (
        <div
          className={classNames(
            'mt-1 lg:mt-2',
            buttonsAlignment === ButtonsAlignment.Vertical &&
              'flex-col justify-center items-center w-full',
            buttonsAlignment === ButtonsAlignment.VerticalCentered &&
              'flex-col justify-center items-center w-full flex-1',
            'flex gap-1 sm:gap-2 lg:gap-4',
            buttonsLayout === ButtonsLayout.TWO_COLUMNS &&
              'container grid grid-cols-2',
          )}
        >
          <ButtonsList
            prefixId={prefixId}
            buttons={innerButtons}
            onFinish={onFinish}
            onFallback={onFallback}
            onBack={onBack}
            onNext={onNext}
            onJumpToSlide={onJumpToSlide}
          />
        </div>
      )}
      {/* {(isShowBackButton || isShowFinishButton || isShowNextButton) && (
        <div className="flex gap-2 mt-2">
          {isShowBackButton && backButton && (
            <Button
              className={backButton.className}
              buttonStyle={backButton.buttonStyle}
              onClick={() => onBack()}
            >
              {backButton.title}
            </Button>
          )}
          {isShowFinishButton && finishButton && (
            <Button
              className={classNames('flex-1', finishButton.className)}
              buttonStyle={finishButton.buttonStyle}
              onClick={() => onFinish()}
            >
              {finishButton.title}
            </Button>
          )}
          {isShowNextButton && nextButton && (
            <Button
              className={classNames('flex-1', nextButton.className)}
              buttonStyle={nextButton.buttonStyle}
              onClick={() => onNext()}
            >
              {nextButton.title}
            </Button>
          )}
        </div>
      )} */}
    </div>
    {verticalElement && <div className="w-1/4">{verticalElement}</div>}
  </div>
);

export default GamePopupElement;
