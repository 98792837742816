import classNames from 'classnames';
import {forwardRef, useCallback, useImperativeHandle, useState} from 'react';
import {motion} from 'framer-motion';
import './BattleInfo.scoped.css';

interface BattleProperty {
  id: string;
  iconScr: string; // src иконки
  value: string;
}

export enum Orientation {
  LEFT,
  RIGHT,
}

interface BattleInfoProps extends React.HTMLProps<HTMLDivElement> {
  avatarSrc: string; // src аватара
  orientation: Orientation; // Ориентация аватара и прочего ui слева или справа

  isClock?: boolean;

  totalHealth: number;
  totalEnergy: number;
  properties: BattleProperty[];
}

export interface BattleInfoRefProps {
  changeHealth: (val: number) => void;
  changeEnergy: (val: number) => void;
  setEnergy: (val: number) => void;
}

const BattleInfo = forwardRef<BattleInfoRefProps, BattleInfoProps>(
  (
    {
      className,
      avatarSrc,
      orientation,
      totalHealth,
      totalEnergy,
      properties,
      isClock = false,
      ...rest
    },
    ref,
  ) => {
    const [localHealths, setLocalHealths] = useState(totalHealth);
    const [localEnergy, setLocalEnergy] = useState(totalEnergy);

    // изменение hp
    const changeHealth = useCallback(
      (val: number) => {
        const newVal = localHealths + val;
        if (newVal < 0) {
          setLocalHealths(0);
          return;
        }
        if (newVal > totalHealth) {
          setLocalHealths(totalHealth);
          return;
        }
        setLocalHealths(newVal);
      },
      [localHealths, totalHealth],
    );

    // изменение energy
    const changeEnergy = useCallback(
      (val: number) => {
        const newVal = localEnergy + val;
        if (newVal < 0) {
          setLocalEnergy(0);
          return;
        }
        if (newVal > totalEnergy) {
          setLocalEnergy(totalEnergy);
          return;
        }
        setLocalEnergy(newVal);
      },
      [localEnergy, totalEnergy],
    );

    useImperativeHandle(ref, () => ({
      changeHealth,
      changeEnergy,
      setEnergy: (val) => {
        if (val < 0) {
          setLocalEnergy(0);
          return;
        }
        if (val > totalEnergy) {
          setLocalEnergy(totalEnergy);
          return;
        }
        setLocalEnergy(val);
      },
    }));

    // получаем hp в процентах
    const getPercentHealth = useCallback(() => {
      return `${Math.round((localHealths / totalHealth) * 100)}%`;
    }, [localHealths, totalHealth]);

    // получаем energy в процентах
    const getPercentEnergy = useCallback(() => {
      return `${Math.round((localEnergy / totalEnergy) * 100)}%`;
    }, [localEnergy, totalEnergy]);

    return (
      <div
        className={classNames(
          'absolute w-1/2 z-0',
          orientation === 0 ? 'left-0' : 'right-0',
          className,
        )}
        {...rest}
      >
        <div className="mt-10 relative">
          <div
            className={classNames(
              'absolute z-10 text-game-base h-[120%] top-[-38%] p-1 bg-gray-500 rounded-full',
              orientation === 0 ? 'left-[1%]' : 'right-[1%]',
            )}
          >
            <img
              className={classNames(
                'h-full',
                orientation !== 0 && 'scale-x-[-1]',
              )}
              src={avatarSrc}
            />
          </div>
          {/* <img
            src={avatarSrc}
            className={classNames(
              'absolute z-10 text-game-base h-[120%] top-[-10%] p-2',
              orientation === 0 ? 'left-[1%]' : 'right-[1%]',
            )}
          /> */}

          <div
            className={classNames(
              'top-bars',
              orientation === 0
                ? 'ml-[10%]'
                : 'mr-[8%] flex flex-col items-end',
            )}
          >
            <div className="health-bar-red w-2/3 overflow-hidden relative">
              <div className="absolute w-full h-full health-bar-red-top -top-1/2" />
              <motion.div
                layout
                transition={{duration: 0.5, ease: 'backInOut'}}
                className={classNames(
                  'absolute h-full health-bar-green overflow-hidden',
                  orientation === 1 && 'right-0',
                )}
                style={{
                  width:
                    getPercentHealth() !== '0%'
                      ? `${getPercentHealth()}`
                      : '0.5%',
                }}
              >
                <div className="absolute w-full h-full health-bar-green-top -top-1/2" />
              </motion.div>
              <div className="relative flex justify-center items-center w-full h-full">
                <span className="font-sigmarOne text-percent text-game-base text-white">
                  {getPercentHealth()}
                </span>
              </div>
            </div>
            <div
              className={classNames(
                'mt-1 lg:mt-3 relative w-full',
                orientation === 0
                  ? 'ml-[5%] sm:ml-[3%] lg:ml-[1%]'
                  : 'mr-[7%] sm:mr-[5%] lg:mr-[3%] flex justify-end',
              )}
            >
              <img
                src={!isClock ? '/stemmy/lightning.png' : '/stemmy/clock.png'}
                className={classNames(
                  'absolute z-10 text-game-base h-[125%] -top-[10%]',
                  orientation === 0 ? '-left-[1%]' : '-right-[1%]',
                )}
              />
              <div className="health-bar-gray w-1/2 overflow-hidden relative">
                <div className="absolute w-full h-full health-bar-gray-top -top-1/2" />
                <motion.div
                  className={classNames(
                    'absolute h-full health-bar-yellow overflow-hidden',
                    orientation === 1 && 'right-0',
                  )}
                  layout
                  transition={{duration: 0.5, ease: 'backInOut'}}
                  style={{
                    width:
                      getPercentEnergy() !== '0%'
                        ? `${getPercentEnergy()}`
                        : '0.5%',
                  }}
                >
                  <div className="absolute w-full h-full health-bar-yellow-top -top-1/2" />
                </motion.div>
                <div className="relative flex justify-center items-center w-full h-full">
                  <span className="font-sigmarOne text-transparent text-game-base text-white invisible">
                    {getPercentEnergy()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(orientation === 1 && 'flex justify-end')}>
          <div className="mt-6 bottom-bars flex w-[60%]">
            {properties.map((proper) => {
              return (
                <div
                  key={proper.id}
                  className={classNames(
                    'flex gap-1 items-center w-1/5',
                    orientation === 0 ? 'ml-2' : 'mr-2',
                  )}
                >
                  <img src={proper.iconScr} className="w-full" />
                  <div className="font-sigmarOne text-white text-game-sm">
                    {proper.value}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className={classNames(orientation === 1 && 'flex justify-end')}>
          <Button onClick={() => changeHealth(1)}>+1 hp</Button>
          <Button onClick={() => changeHealth(-1)}>-1 hp</Button>
        </div>
        <div className={classNames(orientation === 1 && 'flex justify-end')}>
          <Button onClick={() => changeEnergy(1)}>+1 en</Button>
          <Button onClick={() => changeEnergy(-1)}>-1 en</Button>
        </div> */}
      </div>
    );
  },
);

export default BattleInfo;
