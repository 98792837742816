import React from 'react';
import {motion} from 'framer-motion';
import {divVariants} from '../../styles/framerMotionVariants';
import './StemmyTextFieldWithHead.scoped.css';

export interface StemmyTextFieldWithHeadProps {
  question?: string;
}

const StemmyTextFieldWithHead: React.FC<StemmyTextFieldWithHeadProps> = ({
  question,
}) => {
  return (
    <motion.div
      initial={'hidden'}
      animate={'visible'}
      transition={{duration: 0.25}}
      variants={divVariants}
      className="rounded-lg w-full h-full flex items-center justify-center px-4 sm:px-10 py-5 bg-purple-gradient text-white text-center cursor-default mx-auto font-roboto font-bold relative text-game-lg"
    >
      <div className="absolute top-0 left-0 w-8 h-8 sm:w-14 sm:h-14">
        <img
          src="/stemmy/e_head.png"
          className="absolute w-8 sm:w-14 -top-1/2 -left-1/2"
        />
      </div>
      {question}
    </motion.div>
  );
};
export default StemmyTextFieldWithHead;
