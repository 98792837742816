import Line from './Line';
import {useState} from 'react';
import './Leaderboard.scoped.css';
import {motion} from 'framer-motion';

export interface LeaderboardLine {
  place: number;
  name: string;
  isHighlightet?: boolean;
  customProperties: LeadrboardTableProperty[];
}

export interface LeadrboardTableProperty {
  value: string;
}

export interface LeaderboardProps {
  lines: LeaderboardLine[];

  title: string;
  text: string;
}

const Leaderboard: React.FC<LeaderboardProps> = ({lines, title, text}) => {
  const [tableHovered, setTableHovered] = useState(false);

  return (
    <div className="mb-4">
      {/* <img
        src="/stemmy/star.png"
        alt="Star"
        className="w-1/6 hidden md:block"
      /> */}
      <div className="flex flex-col w-full">
        <h1 className="font-sigmarOne text-game-lg lg:pb-2">{title}</h1>
        <h2 className="font-roboto text-game-sm font-bold leaderboard-text-shadow pb-3 lg:pb-10">
          {text}
        </h2>
        <motion.div
          className="flex flex-col gap-2 lg:gap-3 relative"
          onHoverStart={() => setTableHovered(true)}
          onHoverEnd={() => setTableHovered(false)}
        >
          {lines.map((line, index) => {
            return (
              <Line
                isFirstLine={index === 0}
                isHighlightet={line.isHighlightet}
                key={index}
                name={line.name}
                place={line.place}
                value={line.customProperties[0].value}
              />
            );
          })}
        </motion.div>
      </div>
    </div>
  );
};
export default Leaderboard;
