import {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import * as THREE from 'three';
import {useLoader} from '@react-three/fiber';
import {ModelTransform} from '../../../types/models/BasicTypes';
import {animated, useSpring} from '@react-spring/three';
import GameSound, {GameSoundRefProps} from './Sound';

export interface HitEffectRefProps {
  hitAnimation: () => void;
}

interface HitEffectProps extends Omit<ModelTransform, 'scale'> {
  audioSrc: string;
}

const HitEffect = forwardRef<HitEffectRefProps, HitEffectProps>(
  ({position, rotation, audioSrc}, ref) => {
    const group = useRef<THREE.Group>() as React.MutableRefObject<THREE.Group>;
    const attackSoundRef = useRef<GameSoundRefProps>(null);

    const texture = useLoader(THREE.TextureLoader, '/assets/hitEffect.png');

    const [active, setActive] = useState(false);

    const springAnimation = useSpring({
      scale: active ? 1.5 : 0.5,
      opacity: active ? 0.8 : 0,
      config: {
        tension: 180,
        friction: 12,
        duration: 200,
      },
    });

    useImperativeHandle(ref, () => ({
      hitAnimation() {
        attackSoundRef.current?.play();
        setActive(true);
        setTimeout(() => {
          setActive(false);
        }, 200);
      },
    }));

    return (
      <group position={position} ref={group} rotation={rotation}>
        <GameSound ref={attackSoundRef} src={audioSrc} />
        <animated.mesh scale={springAnimation.scale}>
          <planeBufferGeometry attach="geometry" />
          <meshBasicMaterial
            transparent
            opacity={active ? 0.8 : 0}
            attach="material"
            map={texture}
            toneMapped={false}
          />
        </animated.mesh>
      </group>
    );
  },
);
export default HitEffect;
