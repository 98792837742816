import {useAnimations, useGLTF} from '@react-three/drei';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import * as THREE from 'three';
import {AnimationAction} from 'three';
import {ModelTransform} from '../../../../types/models/BasicTypes';
import {BodyModel} from '../../../../types/models/BodyModel';
import {HeadModel} from '../../../../types/models/HeadModel';
import {JetpackModel} from '../../../../types/models/JetpackModel';
import {Sword} from '../../../../types/models/WeaponModel';

export interface JetpackCharacterPropsRefProps {
  speak: () => void;
  takeoff: () => void;
  land: () => void;
  dance: () => void;
}

interface JetpackCharacterProps extends ModelTransform {
  headModel?: HeadModel;
  bodyModel?: BodyModel;
  weaponModel?: Sword;
  enableSword?: boolean;
}

const JetpackCharacter = forwardRef<
  JetpackCharacterPropsRefProps,
  JetpackCharacterProps
>(
  (
    {
      headModel,
      bodyModel,
      weaponModel,
      position,
      rotation,
      scale,
      enableSword = false,
    },
    ref,
  ) => {
    const group = useRef<THREE.Group>() as React.MutableRefObject<THREE.Group>;
    const {nodes, materials, animations} = useGLTF(
      '/models/jetpack/PlayerJetPackGlb.glb',
    ) as unknown as JetpackModel;
    const {actions, mixer} = useAnimations(animations, group);

    const [activeAction, setActiveAction] = useState<THREE.AnimationAction>();

    const fadeAction = useCallback(
      (toAction: THREE.AnimationAction | null) => {
        if (!toAction) return;
        if (toAction != activeAction) {
          if (activeAction) {
            console.log('Fading out');
            activeAction.fadeOut(0.2);

            // setTimeout(() => {
            //   activeAction.stop();
            // }, 200);
          }
          toAction.reset();
          toAction.fadeIn(0.2);
          toAction.play();

          setActiveAction(toAction);
        }
      },
      [activeAction],
    );

    // const setAction = useCallback(
    //   (toAction: THREE.AnimationAction | null) => {
    //     if (!toAction) return;

    //     if (toAction != activeAction) {
    //       if (activeAction) {
    //         activeAction.stop();
    //       }

    //       toAction.reset();
    //       toAction.play();

    //       setActiveAction(toAction);
    //     }
    //   },
    //   [activeAction],
    // );

    const onAnimationFinished = useCallback(
      (
        e: THREE.Event & {
          type: 'finished';
        } & {
          target: THREE.AnimationMixer;
          action?: AnimationAction;
        },
      ) => {
        console.log('On finished triggered');
        // if (activeAction !== e.action) {
        //   console.log('On finished prevented: this aniamtion is obsolete');
        //   return;
        // }

        switch (e.action) {
          case actions.IdleJetStart:
            console.log('Auto change to IdleJetFly');
            fadeAction(actions.IdleJetFly);
            break;

          case actions.IdleJetEnd:
            console.log('Auto change to IdleJetOFF');
            fadeAction(actions.IdleJetOFF);
            break;
        }
      },
      [
        actions.IdleJetEnd,
        actions.IdleJetFly,
        actions.IdleJetOFF,
        actions.IdleJetStart,
        fadeAction,
      ],
    );

    useEffect(() => {
      fadeAction(actions.Greeting);
      mixer.addEventListener('finished', onAnimationFinished);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
      speak() {
        const idleanimation = actions.IdleJetOFF;
        if (idleanimation) {
          fadeAction(idleanimation);
        }
      },
      takeoff() {
        const takeOffAnimation = actions.IdleJetStart;

        if (takeOffAnimation) {
          takeOffAnimation.clampWhenFinished = true;
          takeOffAnimation.setLoop(THREE.LoopOnce, 1);
          fadeAction(takeOffAnimation);
        }
      },
      land() {
        const landAnimation = actions.IdleJetEnd;
        if (landAnimation) {
          landAnimation.clampWhenFinished = true;
          landAnimation.setLoop(THREE.LoopOnce, 1);
          fadeAction(landAnimation);
        }
      },
      dance() {
        fadeAction(actions.Dance);
      },
    }));

    return (
      <group
        ref={group}
        position={position}
        rotation={rotation}
        scale={scale}
        dispose={null}
      >
        <group name="Scene">
          <group name="Armature">
            <primitive object={nodes.root} />
            <skinnedMesh
              name="BODY"
              geometry={
                bodyModel ? bodyModel.nodes.BODY.geometry : nodes.BODY.geometry
              }
              material={materials.BodiesPBRDefault}
              skeleton={nodes.BODY.skeleton}
              castShadow
            />
            <skinnedMesh
              name="HEAD"
              geometry={
                headModel ? headModel.nodes.HEAD.geometry : nodes.HEAD.geometry
              }
              material={materials.HeadsPBRDefault}
              skeleton={nodes.HEAD.skeleton}
              castShadow
            />
            <skinnedMesh
              name="JetPack"
              geometry={nodes.JetPack.geometry}
              material={materials.BodiesPBRDefault}
              skeleton={nodes.JetPack.skeleton}
            />
            {enableSword && (
              <skinnedMesh
                name="SWORD"
                geometry={
                  weaponModel
                    ? weaponModel.nodes.SWORD.geometry
                    : nodes.SWORD.geometry
                }
                material={materials.BlueWeapon}
                skeleton={nodes.SWORD.skeleton}
                castShadow
              />
            )}
          </group>
        </group>
      </group>
    );
  },
);
export default JetpackCharacter;
