import React, {useCallback, useEffect, useState} from 'react';
import StemmyTextFieldWithHead from '../../textField/purpleWithHead/StemmyTextFieldWithHead';
import StemmyPurpleButton from '../../buttons/purpleLong/StemmyPurpleButton';
import './DialogView.scoped.css';
import {Dialog, DialogAnswer} from '../../../pages/dialogs/StemmyDialogsPage';
import {shuffle} from 'lodash';
import Button, {ButtonStyle} from '../../form/button/Button';
import classNames from 'classnames';

export interface StemmyDialogViewProps {
  dialog: Dialog;
  isShuffleAnswers: boolean;
  onDialogAnswered: () => void;
}

const StemmyDialogView: React.FC<StemmyDialogViewProps> = ({
  dialog,
  isShuffleAnswers,
  onDialogAnswered,
}) => {
  const [localaAnswers, setLocalaAnswers] = useState<DialogAnswer[]>();
  const [selectedAnswerId, setSelectedAnswerId] = useState<string>();

  const OnButtonClick = useCallback(
    (answer: DialogAnswer) => {
      setSelectedAnswerId(answer.answerId);
      if (answer.onAnswer) answer.onAnswer();
      onDialogAnswered();
    },
    [onDialogAnswered],
  );

  useEffect(() => {
    setSelectedAnswerId(undefined);
    setLocalaAnswers(
      isShuffleAnswers ? shuffle(dialog.answers) : dialog.answers,
    );
  }, [dialog, isShuffleAnswers]);

  if (!localaAnswers) return <div>No answers</div>;
  return (
    <div className="w-full flex flex-col justify-end py-10 lg:py-2 gap-3">
      <StemmyTextFieldWithHead question={dialog.question} />
      <div className="grid grid-cols-1 px-2 sm:px-10 gap-y-2 lg:gap-y-3 items-center justify-items-center">
        {localaAnswers.map((answer, index) => {
          return (
            <Button
              key={index}
              onClick={() => OnButtonClick(answer)}
              className="w-full"
              buttonStyle={ButtonStyle.Purple}
              isSelected={answer.answerId === selectedAnswerId}
            >
              <span className="text-game-lg">{answer.answer}</span>
            </Button>
            // <StemmyPurpleButton
            //   key={index}
            //   answer={answer}
            //   isAnswerDone={!!selectedAnswerId}
            //   isSelectedAnswer={answer.answerId === selectedAnswerId}
            //   onClick={() => OnButtonClick(answer)}
            // />
          );
        })}
      </div>
    </div>
  );
};

export default StemmyDialogView;
