import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {ApiError} from '../../basicTypes/ApiError';
import {UseShortMutationOptions} from '../../../helpers/ReactQuesyOptionBuilder';
import {IntegrationUserDAO} from './dao/IntegrationUserDAO';
import {IntegrationUserDTO} from './dto/IntegrationUserDTO';
import APIRequest from '../../Api';
import {GlobalNotificationErrorHandler} from '../../../services/globalNotification/errorHandler';

const CreateNewIntegrationUserKey = 'integration/user/create';

export const useCreateNewIntegrationUser = (
  options?: UseShortMutationOptions<
    IntegrationUserDTO,
    AxiosError<ApiError>,
    IntegrationUserDAO
  >,
) => {
  return useMutation<
    IntegrationUserDTO,
    AxiosError<ApiError>,
    IntegrationUserDAO
  >(
    [CreateNewIntegrationUserKey],
    async (newSimulation: IntegrationUserDAO): Promise<IntegrationUserDTO> => {
      const {data} = await APIRequest.post<IntegrationUserDTO>(
        `/Integration/Register`,
        newSimulation,
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};
