import classNames from 'classnames';
import {animate, HTMLMotionProps, motion, useMotionValue} from 'framer-motion';
import {useEffect} from 'react';
import {InformingViewAnimation} from '../mechanics/InformingSliderView/InformingView';

interface AnimatedDivProps extends HTMLMotionProps<'div'> {
  children: React.ReactNode;
  animation: InformingViewAnimation;
}

const AnimatedDiv: React.FC<AnimatedDivProps> = ({
  animation,
  children,
  ...rest
}) => {
  const x = useMotionValue('0%');
  const opacity = useMotionValue(0);
  const scaleX = useMotionValue('100%');
  const scaleY = useMotionValue('100%');

  useEffect(() => {
    if (animation === InformingViewAnimation.Back) {
      animate(x, ['-100%', '0%', '0%']);
      animate(opacity, 1, {
        duration: 0.5,
      });
    } else if (animation === InformingViewAnimation.Next) {
      animate(x, ['100%', '0%', '0%']);
      animate(opacity, 1, {
        duration: 0.5,
      });
    } else if (animation === InformingViewAnimation.Hidden) {
      animate(x, ['0%'], {
        duration: 0,
      });
    } else {
      animate(opacity, 1, {
        duration: 0.5,
      });
      animate(scaleX, ['80%', '105%', '100%'], {
        duration: 0.5,
      });
      animate(scaleY, ['90%', '105%', '100%'], {
        duration: 0.5,
      });
    }
  }, []);

  return (
    <motion.div
      key={animation}
      style={{x, opacity, scaleX, scaleY}}
      className={classNames(
        rest.className,
        animation === InformingViewAnimation.Hidden && 'hidden',
      )}
      {...rest}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedDiv;
