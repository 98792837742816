import {useAnimations, useGLTF} from '@react-three/drei';
import {forwardRef, useEffect, useImperativeHandle, useRef} from 'react';
import * as THREE from 'three';
import {ModelTransform} from '../../../types/models/BasicTypes';
import {BodyModel} from '../../../types/models/BodyModel';
import {CharacterModel} from '../../../types/models/CharacterFullModel';
import {HeadModel} from '../../../types/models/HeadModel';
import {Sword} from '../../../types/models/WeaponModel';

export interface CharacterRefProps {
  attack: () => void;
  getDamage: () => void;
}

interface CharacterProps extends ModelTransform {
  headModel?: HeadModel;
  bodyModel?: BodyModel;
  weaponModel?: Sword;
  enableSword?: boolean;
}

const Character = forwardRef<CharacterRefProps, CharacterProps>(
  (
    {
      headModel,
      bodyModel,
      weaponModel,
      position,
      rotation,
      scale,
      enableSword = false,
    },
    ref,
  ) => {
    const group = useRef<THREE.Group>() as React.MutableRefObject<THREE.Group>;
    const {nodes, materials, animations} = useGLTF(
      'https://storage.googleapis.com/stemmy-integrations/models/character/RigPlayerAnimationsMaterials.glb',
    ) as unknown as CharacterModel;
    const {actions} = useAnimations(animations, group);

    useEffect(() => {
      const idleanimation = actions.Idle;
      const attakanimation = actions.SwordAttack;
      if (!idleanimation || !attakanimation) return;

      attakanimation.setLoop(THREE.LoopOnce, 1);
      attakanimation.clampWhenFinished = true;

      idleanimation.play();
      if (enableSword) attakanimation.play().reset();
    }, [actions, enableSword]);

    useImperativeHandle(ref, () => ({
      attack() {
        const rnd = Math.random();

        let animation: THREE.AnimationAction | null;

        if (rnd < 0.33) {
          animation = actions.SwordAttack;
          if (!enableSword) animation = actions.SwordAttack2;
        } else if (rnd < 0.66) {
          animation = actions.SwordAttack2;
        } else {
          animation = actions.SwordAttack3;
        }

        if (!animation) return;

        animation.setLoop(THREE.LoopOnce, 1);
        animation.clampWhenFinished = enableSword;
        animation.setDuration(0.7);
        animation.enabled = true;
        animation.play().reset();
      },
      getDamage() {
        const animation = actions.GetDamage;
        if (!animation) return;
        animation.reset();
        animation.setLoop(THREE.LoopOnce, 1);
        animation.play().reset();
      },
    }));

    return (
      <group
        ref={group}
        position={position}
        rotation={rotation}
        scale={scale}
        dispose={null}
      >
        <group name="Scene">
          <group name="Armature">
            <primitive object={nodes.root} />
            <skinnedMesh
              name="BODY"
              geometry={
                bodyModel ? bodyModel.nodes.BODY.geometry : nodes.BODY.geometry
              }
              material={materials.BodiesPBRDefault}
              skeleton={nodes.BODY.skeleton}
              castShadow
            />
            <skinnedMesh
              name="HEAD"
              geometry={
                headModel ? headModel.nodes.HEAD.geometry : nodes.HEAD.geometry
              }
              material={materials.HeadsPBRDefault}
              skeleton={nodes.HEAD.skeleton}
              castShadow
            />
            {enableSword && (
              <skinnedMesh
                name="SWORD"
                geometry={
                  weaponModel
                    ? weaponModel.nodes.SWORD.geometry
                    : nodes.SWORD.geometry
                }
                material={materials.BlueWeapon}
                skeleton={nodes.SWORD.skeleton}
                castShadow
              />
            )}
          </group>
        </group>
      </group>
    );
  },
);
export default Character;
