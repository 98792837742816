import {useAnimations, useGLTF} from '@react-three/drei';
import React, {useEffect} from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import * as THREE from 'three';
import {ModelTransform} from '../../../types/models/BasicTypes';
import {FireballModel} from '../../../types/models/FirebasllModel';

export interface FireballRefProps {
  despawn: () => void;
  flytotarget: () => void;
  idle: () => void;
  spawn: () => void;
}

const Fireball = forwardRef<FireballRefProps, ModelTransform>(
  ({position, rotation, scale}, ref) => {
    const group = useRef<THREE.Group>() as React.MutableRefObject<THREE.Group>;
    const {nodes, materials, animations} = useGLTF(
      'https://storage.googleapis.com/stemmy-integrations/models/others/FireBall.glb',
    ) as unknown as FireballModel;
    const animator = useAnimations(animations, group);

    useEffect(() => {}, []);

    useImperativeHandle(ref, () => ({
      despawn() {
        const animation = animator.actions.DeSpawn;
        if (!animation) return;

        animation.setLoop(THREE.LoopOnce, 1);
        animation.clampWhenFinished = true;
        animation.enabled = true;
        animation.play().reset();
      },
      flytotarget() {
        const animation = animator.actions.FlyToTarget;
        if (!animation) return;
        animation.play();
      },
      idle() {
        const animation = animator.actions.Idle;
        if (!animation) return;

        animation.play();
      },
      spawn() {
        const animation = animator.actions.Spawn;
        if (!animation) return;

        animation.setLoop(THREE.LoopOnce, 1);
        animation.clampWhenFinished = true;
        animation.enabled = true;
        animation.play().reset();

        // animator.mixer.addEventListener('finished', (elem) => {
        //   const t = elem.target as THREE.AnimationMixer;
        //   console.log(t.clipAction.);
        // });

        // setTimeout(() => {}, anims.mixer.on);
      },
    }));

    return (
      <group
        ref={group}
        dispose={null}
        position={position}
        rotation={rotation}
        scale={scale}
      >
        <group name="Scene">
          <group name="Armature" rotation={[0, -Math.PI / 10, 0]}>
            <primitive object={nodes.Root} />
            <skinnedMesh
              name="Sphere"
              geometry={nodes.Sphere.geometry}
              material={materials.Def}
              skeleton={nodes.Sphere.skeleton}
            />
            <skinnedMesh
              name="Sphere001"
              geometry={nodes.Sphere001.geometry}
              material={materials.Def}
              skeleton={nodes.Sphere001.skeleton}
            />
          </group>
        </group>
      </group>
    );
  },
);
export default Fireball;
