import classNames from 'classnames';
import React from 'react';
import {ButtonSize} from '../../buttons/yellowShort/StemmyYellowButton';
import './Button.scoped.css';

type ButtonProps = {
  btnSize?: ButtonSize;
  noRadius?: boolean;
  disableHover?: boolean;
  buttonStyle?: ButtonStyleDef;
  isSelected?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export interface ButtonStyleDef {
  button: string;
  hover: string;
  buttonUpperPanel: string;
  selected: string;
}

interface ButtonStyleObject {
  [name: string]: ButtonStyleDef;
}

export const ButtonStyle: ButtonStyleObject = {
  Yellow: {
    button: 'bg-stemmy-yellow text-white',
    hover: 'hover:bg-[#bfa005]',
    buttonUpperPanel: 'bg-[#ffe151]',
    selected: '',
  },
  Purple: {
    button: 'bg-stemmy-purple text-white',
    hover: 'hover:bg-[#4E1C97]',
    buttonUpperPanel: 'bg-[#AC62EE]',
    selected: 'bg-[#2d0568] hover:bg-[#2d0568]',
  },
};

const Button: React.FC<ButtonProps> = ({
  className,
  type,
  children,
  btnSize,
  onClick,
  buttonStyle = ButtonStyle.Yellow,
  disableHover = false,
  noRadius = false,
  disabled = false,
  isSelected = false,
  ...rest
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={classNames(
        btnSize,
        !noRadius && 'rounded-md',
        !disableHover && buttonStyle.hover,
        buttonStyle.button,
        isSelected && buttonStyle.selected,
        'px-5 lg:px-10 py-1 text-white font-bold text-center cursor-pointer flex justify-center items-center relative overflow-hidden',
        className,
      )}
      onClick={onClick}
      {...rest}
    >
      <div
        className={classNames(
          buttonStyle.buttonUpperPanel,
          'absolute w-full h-full -top-1/2 opacity-20',
        )}
      />
      <span className="z-10">{children}</span>
    </button>
  );
};
export default Button;
