import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {queryClient} from '../../app/App';
import {UseShortMutationOptions} from '../../helpers/ReactQuesyOptionBuilder';
import {GlobalNotificationErrorHandler} from '../../services/globalNotification/errorHandler';
import APIRequest from '../Api';
import {ApiError} from '../basicTypes/ApiError';
import {TournamentRegisterDTO} from '../Integration/tournament/dto/FinalRegistration';
import {FinalTaskDTO} from '../Integration/tournament/dto/FinalTaskDTO';
import {GetTournamentKey} from '../Integration/tournament/Tournament';
import {TestStatusRequestDTO} from './dto/TestStatusRequest';
import {TestStatusResponseDTO} from './dto/TestStatusResponse';

const HereIntegrationStatusKey = 'here/checkTasks';
const HereIntegrationFinalRegisterKey = 'here/final';

export const useCheckQuest = (
  options?: UseShortMutationOptions<
    TestStatusResponseDTO,
    AxiosError<ApiError>,
    TestStatusRequestDTO
  >,
) => {
  return useMutation<
    TestStatusResponseDTO,
    AxiosError<ApiError>,
    TestStatusRequestDTO
  >(
    [HereIntegrationStatusKey],
    async (params: TestStatusRequestDTO): Promise<TestStatusResponseDTO> => {
      const {data} = await APIRequest.get<TestStatusResponseDTO>(
        `https://api.herewallet.app/partners/stemy`,
        {
          params,
        },
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};

export const useTournamentRegister = (
  options?: UseShortMutationOptions<
    TournamentRegisterDTO,
    AxiosError<ApiError>,
    TournamentRegisterDTO
  >,
) => {
  return useMutation<
    TournamentRegisterDTO,
    AxiosError<ApiError>,
    TournamentRegisterDTO
  >(
    [HereIntegrationFinalRegisterKey],
    async (request: TournamentRegisterDTO): Promise<TournamentRegisterDTO> => {
      await APIRequest.post('/HereIntegration/TournamentRegister', request);
      return request;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};

export const useCompleteFinalTask = (
  options?: UseShortMutationOptions<
    FinalTaskDTO,
    AxiosError<ApiError>,
    FinalTaskDTO
  >,
) => {
  return useMutation<FinalTaskDTO, AxiosError<ApiError>, FinalTaskDTO>(
    [HereIntegrationFinalRegisterKey],
    async (request: FinalTaskDTO): Promise<FinalTaskDTO> => {
      await APIRequest.post('/HereIntegration/GrantAdditionalPoints', request);
      return request;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      onSuccess: () => {
        queryClient.invalidateQueries([
          GetTournamentKey,
          'CE2AD246-F4B8-4010-841D-3626B7218392',
        ]);
      },
      ...options,
    },
  );
};
