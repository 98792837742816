import Leaderboard, {LeaderboardProps} from '../../../leaderboard/Leaderboard';
import GamePopup, {GamePopupProps} from '../InformingSliderView/GamePopup';
import {
  GamePopupFillProps,
  WidnowSize,
} from '../InformingSliderView/GamePopupElement';

interface LeaderboardPopupProps extends Omit<GamePopupProps, 'elements'> {
  id: string;
  prefixId: string;

  leaderboard: LeaderboardProps;
  element: Omit<GamePopupFillProps, 'horizontalElement'>;
}

const LeaderboardPopup: React.FC<LeaderboardPopupProps> = ({
  leaderboard,
  element,
  ...rest
}) => {
  return (
    <GamePopup
      elements={[
        {
          windowSize: WidnowSize.Big,
          horizontalElement: <Leaderboard {...leaderboard} />,
          ...element,
        },
      ]}
      {...rest}
    />
  );
};

export default LeaderboardPopup;
