import classNames from 'classnames';
import {WidnowSize} from './GamePopupElement';

interface InformingViewProps {
  children: React.ReactNode;
  buttons?: React.ReactNode;
  windowSize?: WidnowSize;
  conversationImage?: string;
  style?: PopupStyle;
}

export enum PopupStyle {
  Purple = 'bg-purple-gradient text-white',
  Yellow = 'bg-gradient-to-r from-yellow-600 via-yellow-700 to-yellow-800 text-white',
}

export enum InformingViewAnimation {
  Initial,
  Back,
  Next,
  Hidden,
}

const InformingView: React.FC<InformingViewProps> = ({
  children,
  conversationImage,
  buttons,
  style = PopupStyle.Purple,
  windowSize = WidnowSize.Auto,
}) => (
  <div
    style={{maxWidth: windowSize === WidnowSize.Fullscreen ? 'none' : '75%'}}
    className={windowSize}
  >
    <div
      className={classNames(
        style,
        windowSize === WidnowSize.Fullscreen
          ? 'w-full h-full p-2 sm:p-4 lg:p-8'
          : 'rounded-lg px-4 sm:px-14 py-1 lg:py-4 relative mx-auto',
      )}
    >
      {windowSize !== WidnowSize.Fullscreen && conversationImage && (
        <div className="absolute top-0 left-0 w-8 lg:w-20 h-8 lg:h-14">
          <img
            src={conversationImage}
            className="absolute -top-1/2 -left-1/2"
          />
        </div>
      )}
      {children}
    </div>
    {buttons}
  </div>
);
export default InformingView;
