import {useState} from 'react';
import {motion} from 'framer-motion';
import classNames from 'classnames';
import './Leaderboard.scoped.css';

export interface LineProps {
  place: number;
  name: string;
  value: string;
  isFirstLine?: boolean;
  isHighlightet?: boolean;
}

const Line: React.FC<LineProps> = ({
  place,
  name,
  value,
  isHighlightet = false,
  isFirstLine = false,
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div className="flex leaderboard-line gap-2">
      <div className="w-1/5 z-10 font-sigmarOne text-white xl:px-4">
        <div className="bg-[#8444EE] px-4 text-game-base relative rounded-2xl w-full">
          <img
            src="/stemmy/place-button.png"
            className="absolute w-full h-full top-0 left-0"
          />
          <span
            className={classNames(
              'z-10 relative flex justify-center',
              isHighlightet && !hovered ? 'text-[#FED467]' : 'text-white',
            )}
          >
            {place}TH
          </span>
        </div>
      </div>
      <div
        className={classNames(
          'w-2/5 z-10 text-game-base px-4 font-bold',
          isHighlightet && !hovered ? 'text-[#FED467]' : 'text-white',
        )}
      >
        {name}
      </div>
      <div
        className={classNames(
          'w-2/5 z-10 text-game-base text-center font-bold',
          isHighlightet && !hovered ? 'text-[#FED467]' : 'text-white',
        )}
      >
        Score: {value}
      </div>
    </div>
  );
  return (
    <motion.div
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
      className={classNames(
        'leaderboard-line px-2 lg:px-8 py-1 lg:py-4 flex justify-between items-center w-11/12 relative overflow-hidden uppercase cursor-pointer',
        hovered ? 'bg-[#71D91A]' : 'bg-[#431b7c]',
      )}
      transition={{duration: 0.5}}
    >
      <div
        className={classNames(
          'absolute w-full h-full -top-[20%] left-0 lg:border-b-[10px] rounded-2xl',
          hovered
            ? 'bg-[#71D91A] border-[#86F22B]'
            : 'bg-[#481E83] border-[#4F228E]',
        )}
      />
      <div className="w-1/5 z-10 font-sigmarOne text-white xl:px-4">
        <div className="bg-[#8444EE] px-4 text-game-base relative rounded-2xl w-full">
          <img
            src="/stemmy/place-button.png"
            className="absolute w-full h-full top-0 left-0"
          />
          <span className="z-10 relative flex justify-center">
            {place}TH{isHighlightet && '*'}
          </span>
        </div>
      </div>
      <div className="w-2/5 z-10 font-sigmarOne text-game-sm px-4">{name}</div>
      <div
        className={classNames(
          'w-2/5 z-10 font-sigmarOne text-game-sm text-center',
          isHighlightet && !hovered ? 'text-[#FED467]' : 'text-white',
        )}
      >
        {value}
      </div>
    </motion.div>
  );
};
export default Line;
