import classNames from 'classnames';
import {AnimatePresence} from 'framer-motion';
import mixpanel from 'mixpanel-browser';
import {useCallback, useEffect, useState} from 'react';
import AnimatedDiv from '../../utils/AnimatedDiv';
import BlackBackground from '../../utils/BlackBackground';
import ButtonsList from './ButtonsList';
import GamePopupElement, {
  ButtonsLayout,
  GamePopupFillProps,
  WidnowSize,
} from './GamePopupElement';
import InformingView, {InformingViewAnimation} from './InformingView';

export interface GamePopupProps {
  prefixId: string;
  id: string;

  elements: GamePopupFillProps[];
  active?: boolean;

  onStart?: () => void;
  onFinish?: () => void;

  tempHidden?: boolean;
}

const GamePopup: React.FC<GamePopupProps> = ({
  id,
  prefixId,
  elements,
  onStart,
  onFinish,
  active = true,
  tempHidden = false,
}) => {
  const [popupId] = useState(`${prefixId}.${id}`);

  const [animation, setAnimation] = useState<InformingViewAnimation>(
    InformingViewAnimation.Next,
  );

  const [startTriggered, setStartTriggered] = useState(false);
  useEffect(() => {
    if (!startTriggered && active) {
      setStartTriggered(true);
      console.log(`STATISTICS: launched popup: "${popupId}"`);

      mixpanel.track('Popup launch', {
        prefix: prefixId,
        id: id,
      });

      if (onStart) onStart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  useEffect(() => {
    if (!active) setStartTriggered(false);
  }, [active]);

  const [currentElementIndex, setCurrentElementIndex] = useState(0);

  const [fallback, setFallback] = useState<GamePopupFillProps>();

  useEffect(() => {
    if (tempHidden) setAnimation(InformingViewAnimation.Hidden);
    else setAnimation(InformingViewAnimation.Next);
  }, [tempHidden]);

  const OnFinishAnimation = useCallback(() => {
    if (onFinish) onFinish();
  }, [onFinish]);

  const OnNextAnimation = useCallback(() => {
    console.log('On next animation');
    if (currentElementIndex + 1 >= elements.length) {
      OnFinishAnimation();
      return;
    }

    setAnimation(InformingViewAnimation.Next);
    setCurrentElementIndex((x) => {
      const index = x + 1;
      return index;
    });
  }, [OnFinishAnimation, currentElementIndex, elements.length]);

  const OnBackAnimation = useCallback(() => {
    if (currentElementIndex <= 0) return;

    setAnimation(InformingViewAnimation.Back);
    setCurrentElementIndex((x) => {
      const index = x - 1;
      return index;
    });
  }, [currentElementIndex]);

  const OnFallback = useCallback(() => {
    if (
      elements[currentElementIndex] &&
      elements[currentElementIndex].fallback
    ) {
      setTimeout(
        () => setFallback(elements[currentElementIndex].fallback),
        200,
      );
    }
  }, [currentElementIndex, elements]);

  const OnJumpToSlide = useCallback(
    (val: number) => {
      if (val < 0 || val >= elements.length) return;

      if (currentElementIndex < val) setAnimation(InformingViewAnimation.Next);
      else setAnimation(InformingViewAnimation.Back);
      setCurrentElementIndex(() => {
        const index = val;
        return index;
      });
    },
    [currentElementIndex, elements.length],
  );

  if (!elements[currentElementIndex]) return <div>No current element!</div>;

  return (
    <div
      className={classNames(
        'w-full h-full aspect-video',
        (!active || animation === InformingViewAnimation.Hidden) && 'hidden',
      )}
    >
      <BlackBackground disabled={tempHidden}>
        <AnimatePresence>
          <AnimatedDiv
            animation={animation}
            key={elements[currentElementIndex].id}
            className={classNames(
              elements[currentElementIndex].windowSize === WidnowSize.Fullscreen
                ? 'top-0 left-0 w-screen h-screen'
                : 'flex flex-col justify-center items-center',
              'w-full h-full relative ',
            )}
          >
            {!fallback && (
              <>
                <InformingView
                  windowSize={elements[currentElementIndex].windowSize}
                  conversationImage={
                    elements[currentElementIndex].conversationImage
                  }
                  buttons={
                    elements[currentElementIndex].externalbuttons &&
                    elements[currentElementIndex].windowSize !==
                      WidnowSize.Fullscreen && (
                      <div
                        className={classNames(
                          'w-full mt-4 grid gap-2 lg:gap-4',
                          elements[currentElementIndex].buttonsLayout ===
                            ButtonsLayout.TWO_COLUMNS &&
                            'container grid-cols-2',
                        )}
                      >
                        <ButtonsList
                          prefixId={popupId}
                          buttons={
                            elements[currentElementIndex].externalbuttons || []
                          }
                          onFinish={OnFinishAnimation}
                          onFallback={OnFallback}
                          onBack={OnBackAnimation}
                          onNext={OnNextAnimation}
                          onJumpToSlide={OnJumpToSlide}
                        />
                      </div>
                    )
                  }
                >
                  <GamePopupElement
                    prefixId={popupId}
                    onNext={OnNextAnimation}
                    onBack={OnBackAnimation}
                    onFinish={OnFinishAnimation}
                    onFallback={OnFallback}
                    onJumpToSlide={OnJumpToSlide}
                    {...elements[currentElementIndex]}
                  />
                </InformingView>
              </>
            )}

            {fallback && (
              <InformingView
                windowSize={elements[currentElementIndex].windowSize}
                conversationImage={
                  elements[currentElementIndex].conversationImage
                }
              >
                <GamePopupElement
                  {...fallback}
                  prefixId={popupId}
                  disableLowerPadding
                  onFinish={OnFinishAnimation}
                  onFallback={OnFallback}
                  onBack={OnBackAnimation}
                  onNext={OnNextAnimation}
                  onJumpToSlide={OnJumpToSlide}
                  innerButtons={[
                    ...(fallback.innerButtons || []),
                    {
                      id: 'fallback_next',
                      title: 'Next',
                      className: 'mt-3',
                      onClick: () => {
                        setFallback(undefined);
                        OnNextAnimation();
                      },
                    },
                  ]}
                />
              </InformingView>
            )}
          </AnimatedDiv>
        </AnimatePresence>
      </BlackBackground>
    </div>
  );
};

export default GamePopup;
