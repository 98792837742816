import React, {useCallback, useEffect, useState} from 'react';
import {AnimatePresence} from 'framer-motion';
import StemmyDialogView from '../../components/stemmy/dialogView/DialogView';
import './StemmyDialogsPage.scoped.css';
import {shuffle} from 'lodash';
import classNames from 'classnames';
import BlackBackground from '../../components/game/utils/BlackBackground';

export interface Dialog {
  question: string;
  answers: DialogAnswer[];
  onClicked: (answerId: string) => void;
}

export interface DialogAnswer {
  answer: string;
  answerId: string;
  onAnswer?: () => void;
}

export interface StemmyDialogsPageProps {
  dialogs: Dialog[];
  active?: boolean;
  onFinished?: () => void;
  shuffleQuestions?: boolean;
  shuffleAnswers?: boolean;
  nextQuestionTimeout?: number;
}

const StemmyDialogsPage: React.FC<StemmyDialogsPageProps> = ({
  dialogs,
  onFinished,
  active = false,
  shuffleQuestions = false,
  shuffleAnswers = false,
  nextQuestionTimeout = 2000,
}) => {
  // const [currentDialog, setCurrentDialog] = useState(0);
  const [gameDialogs, setGameDialogs] = useState<Dialog[]>([]);
  const [currentDialog, setCurrentDialog] = useState<Dialog>();

  const setNextQuestion = useCallback(() => {
    if (gameDialogs.length === 0) {
      if (onFinished) onFinished();
      setCurrentDialog(undefined);
      return;
    }
    const q = gameDialogs;
    setCurrentDialog(q.shift());
    setGameDialogs(q);
  }, [gameDialogs, onFinished]);

  useEffect(() => {
    const questionArr = shuffleQuestions ? shuffle(dialogs) : dialogs;
    setCurrentDialog(questionArr.shift());
    setGameDialogs(questionArr);
  }, [dialogs, shuffleQuestions]);

  if (!currentDialog) return <></>;
  return (
    <div
      className={classNames(
        'aspect-video absolute top-0 left-0 w-full h-full',
        !active && 'hidden',
      )}
    >
      <BlackBackground>
        <div className="w-full h-full flex justify-center items-end">
          <div className="absolute top-0 w-3/4 lg:w-3/5 h-5/6 flex flex-col items-center justify-center z-10">
            <AnimatePresence>
              <StemmyDialogView
                dialog={currentDialog}
                isShuffleAnswers={shuffleAnswers}
                onDialogAnswered={() => {
                  setTimeout(() => {
                    setNextQuestion();
                  }, nextQuestionTimeout);
                }}
              />
            </AnimatePresence>
          </div>
        </div>
      </BlackBackground>
    </div>
  );
};

export default StemmyDialogsPage;
