import {useState} from 'react';
import {useLoadGame} from '../../api/Integration/save/SaveMutation';
import {
  useGameAuthData,
  useRemoveGameAuthData,
} from '../../services/gameAuth/AuthProvider';
import {HereIntegrationSave} from '../../types/save/HereIntegrationSave';
import HereIntegrastionScene from './HereScenario';

const HereIntegrationWrapper: React.FC = () => {
  const [prohibitLoader, setProhibitLoader] = useState(false);
  const [loadData, setLoadData] = useState<HereIntegrationSave>();

  const deleteAuth = useRemoveGameAuthData();

  const loadGame = useLoadGame({
    onSuccess: (val) => {
      console.log('Loaded OK!');
      const data = val as unknown as HereIntegrationSave;
      setLoadData(data);
    },
    onError: () => {
      // alert('Failed to load');
      setProhibitLoader(true);
      deleteAuth.mutate();
    },
  });

  const getAuth = useGameAuthData({
    enabled: prohibitLoader === false,
    onSuccess: (val) => {
      // alert('Success!');
      console.log('Register loaded');
      if (prohibitLoader) return;

      loadGame.mutate({
        userId: val.id,
        integrationId: '791A5C0E-65CA-4284-BB3B-B55F3CEC705A',
      });
    },
    onError: () => {
      // alert('Not registered');
      console.log('Not registered');
      setProhibitLoader(true);
    },
  });

  // useLoadGame(
  //   {
  //     userId: getAuth.data?.id || '',
  //     integrationId: '791A5C0E-65CA-4284-BB3B-B55F3CEC705A',
  //   },
  //   {
  //     enabled: !prohibitLoader && !!getAuth.data?.id,
  //     onSuccess: (val) => {
  //       console.log('Loaded OK!');
  //       const data = val as unknown as HereIntegrationSave;
  //       setLoadData(data);
  //     },
  //     onError: () => {
  //       console.log('Error loading! logging out!');
  //       deleteAuth.mutate();
  //       setProhibitLoader(true);
  //     },
  //   },
  // );

  if (!prohibitLoader && getAuth.isSuccess && !loadData)
    return <div>Loading... </div>;

  return <HereIntegrastionScene loadData={loadData} />;
};

export default HereIntegrationWrapper;
