import {animated, config, useSpring} from '@react-spring/three';
import {Canvas} from '@react-three/fiber';
import {useRef, useState, useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Vector3, Euler, BackSide} from 'three';
import {useCheckQuest} from '../../../api/hereIntegration/HereIntegration';
import {useGameAuthData} from '../../../services/gameAuth/AuthProvider';
import {BodyModel} from '../../../types/models/BodyModel';
import {HeadModel} from '../../../types/models/HeadModel';
import {HereIntegrationSave} from '../../../types/save/HereIntegrationSave';
import Button, {ButtonStyle} from '../../form/button/Button';
import Input from '../../form/input/Input';
import HitEffect, {HitEffectRefProps} from '../effects/HitEffect';
import GameSound, {GameSoundRefProps} from '../effects/Sound';
import AndroidPhone from '../mechanics/androidEmulator/AndroidPhone';
import GamePopup from '../mechanics/InformingSliderView/GamePopup';
import {
  ButtonsAlignment,
  ButtonsLayout,
  WidnowSize,
} from '../mechanics/InformingSliderView/GamePopupElement';
import InformingView from '../mechanics/InformingSliderView/InformingView';
import Character, {CharacterRefProps} from '../models/Character';
import Enimy, {EnimyRefProps} from '../models/Enimy';
import Fireball, {FireballRefProps} from '../models/Fireball';
import BattleInfo, {BattleInfoRefProps, Orientation} from '../ui/BattleInfo';
import BlackBackground from '../utils/BlackBackground';
import {IntegrationSceneProps} from '../utils/IntegrationScene';
import {UsernameCheckChallenge} from './HerePart1';

interface Scene2Props extends IntegrationSceneProps<HereIntegrationSave> {
  headModel?: HeadModel;
  bodyModel?: BodyModel;
}

export enum FailType {
  None,
  QuestFailed,
  IncorrectUsername,
}

const HerePart2: React.FC<Scene2Props> = ({
  headModel,
  bodyModel,
  switchScene,
  prefixId = 'scene_not_set',
}) => {
  const [currectStage, setCurrectStage] = useState(-1);

  const {t} = useTranslation('herePart2');

  const getAuth = useGameAuthData();

  const fireballSoundRef = useRef<GameSoundRefProps>(null);
  const successSound = useRef<GameSoundRefProps>(null);
  const wrongSound = useRef<GameSoundRefProps>(null);

  const bgFightAudio = useRef<GameSoundRefProps>(null);
  const bgNormalAudio = useRef<GameSoundRefProps>(null);

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<UsernameCheckChallenge>({
    defaultValues: {
      nearUserName: getAuth.data?.near,
    },
  });

  const [isFailedChallange, setIsFailedChallange] = useState<FailType>(
    FailType.None,
  );

  const playerRef =
    useRef<CharacterRefProps>() as React.MutableRefObject<CharacterRefProps>;

  const enimyRef =
    useRef<EnimyRefProps>() as React.MutableRefObject<EnimyRefProps>;

  const hitPlayerEffectRef =
    useRef<HitEffectRefProps>() as React.MutableRefObject<HitEffectRefProps>;

  const hitEnimyEffectRef =
    useRef<HitEffectRefProps>() as React.MutableRefObject<HitEffectRefProps>;

  const fireballAnimation =
    useRef<FireballRefProps>() as React.MutableRefObject<FireballRefProps>;

  const PlayerBattleInfo =
    useRef<BattleInfoRefProps>() as React.MutableRefObject<BattleInfoRefProps>;
  const EnimyBattleInfo =
    useRef<BattleInfoRefProps>() as React.MutableRefObject<BattleInfoRefProps>;

  const [isQuestionsHidden, setIsQuestionsHidden] = useState(false);

  const [isLaunched, setIsLaunched] = useState(false);
  const [isFireballVisible, setIsFireballVisible] = useState(false);

  const {fireballPosition} = useSpring({
    fireballPosition: !isLaunched ? -2.5 : 3,
    config: config.stiff,
  });

  const castSpellAnimation = useCallback(() => {
    setIsFireballVisible(true);
    fireballAnimation.current?.spawn();
    fireballSoundRef.current?.play();
    setTimeout(() => {
      setIsLaunched(true);
    }, 2500);
    setTimeout(() => {
      setIsFireballVisible(false);
      setIsLaunched(false);

      enimyRef.current?.getDamage();
      hitEnimyEffectRef.current?.hitAnimation();
    }, 2800);
  }, [enimyRef, fireballAnimation, hitEnimyEffectRef]);

  const onQuestionAnswered = useCallback(
    (isCorrect: boolean, onCompleted?: () => void, damage?: number) => {
      if (isCorrect) {
        successSound.current?.play();
      } else {
        wrongSound.current?.play();
      }

      setIsQuestionsHidden(true);
      setTimeout(() => {
        if (isCorrect) {
          playerRef.current?.attack();
          castSpellAnimation();

          PlayerBattleInfo.current?.changeEnergy(-1);

          setTimeout(() => {
            EnimyBattleInfo.current?.changeHealth(
              damage === undefined ? -1 : -damage,
            );
          }, 3000);

          setTimeout(() => {
            setIsQuestionsHidden(false);
            if (onCompleted) onCompleted();
          }, 4000);
        } else {
          enimyRef.current?.attack();

          EnimyBattleInfo.current?.changeEnergy(-1);

          setTimeout(() => {
            playerRef.current?.getDamage();
            hitPlayerEffectRef.current?.hitAnimation();
            PlayerBattleInfo.current.changeHealth(
              damage === undefined ? -1 : -damage,
            );

            setTimeout(() => {
              setIsQuestionsHidden(false);
              if (onCompleted) onCompleted();
            }, 800);
          }, 400);
        }
      }, 800);
    },
    [
      EnimyBattleInfo,
      castSpellAnimation,
      enimyRef,
      hitPlayerEffectRef,
      playerRef,
    ],
  );

  const checkQuest = useCheckQuest({
    onSuccess: (data) => {
      if (data.test_2 == true) {
        onQuestionAnswered(true, () => {}, 1000);
        setCurrectStage(3);
      } else {
        if (data.detail || !data.test_1)
          setIsFailedChallange(FailType.IncorrectUsername);
        else setIsFailedChallange(FailType.QuestFailed);
      }
    },
    onError: () => setIsFailedChallange(FailType.IncorrectUsername),
  });

  const onCheckQuestSubmit = useCallback(
    (data: UsernameCheckChallenge) => {
      checkQuest.mutate({
        near_account_id: data.nearUserName.toLowerCase(),
      });
    },
    [checkQuest],
  );

  const setStageWithDelay = useCallback((stage: number, delay: number) => {
    setTimeout(() => {
      setCurrectStage(stage);
    }, delay);
  }, []);

  useEffect(() => {
    setStageWithDelay(0, 1000);
  }, [setStageWithDelay]);

  return (
    <div className="relative w-full h-full">
      <div
        style={{
          backgroundImage: 'url("/backgrounds/2.jpg")',
          backgroundSize: 'cover',
        }}
        className="absolute top-0 left-0 w-full h-full"
      >
        <Canvas
          shadows
          camera={{rotation: [-0.3, 0, 0], position: [0, 2.3, 5], fov: 60}}
        >
          <GameSound
            ref={fireballSoundRef}
            src="https://storage.googleapis.com/stemmy-integrations/sfx/Fireball.wav"
          />

          <GameSound
            ref={successSound}
            src="https://storage.googleapis.com/stemmy-integrations/sfx/answerSuccess.wav"
          />
          <GameSound
            ref={wrongSound}
            src="https://storage.googleapis.com/stemmy-integrations/sfx/answerWrong.mp3"
          />

          <GameSound
            ref={bgFightAudio}
            src="https://storage.googleapis.com/stemmy-integrations/audio/bg_fight.mp3"
            autoplay
            loop
          />
          <GameSound
            ref={bgNormalAudio}
            src="https://storage.googleapis.com/stemmy-integrations/audio/bg_normal.mp3"
            loop
          />

          <ambientLight color="#fff" intensity={0.3} />
          <directionalLight
            position={[0, 15, 10]}
            intensity={0.5}
            castShadow
            shadow-mapSize-height={512}
            shadow-mapSize-width={512}
          />
          <HitEffect
            ref={hitPlayerEffectRef}
            position={new Vector3(-2.8, 1, 1)}
            audioSrc="https://storage.googleapis.com/stemmy-integrations/sfx/CombatPunch2.wav"
          />
          <HitEffect
            ref={hitEnimyEffectRef}
            position={new Vector3(3, 1, 1)}
            audioSrc="https://storage.googleapis.com/stemmy-integrations/sfx/Punch1.wav"
          />
          <Character
            ref={playerRef}
            scale={new Vector3(1, 1, 1)}
            position={new Vector3(-3.8, -1, 0)}
            rotation={new Euler(0, 1.5, 0)}
            headModel={headModel}
            bodyModel={bodyModel}
            // weaponModel={swordModel}
          />
          <Enimy
            ref={enimyRef}
            scale={new Vector3(1.5, 1.5, 1.5)}
            position={new Vector3(3.8, -1, 0)}
            rotation={new Euler(0, -1.4, 0)}
          />

          {/* to - (3); from - (-2.5) */}
          <animated.group
            position-x={fireballPosition}
            visible={isFireballVisible}
          >
            <Fireball ref={fireballAnimation} />
          </animated.group>

          <mesh
            position={[0, -1, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            receiveShadow
          >
            <planeBufferGeometry attach="geometry" args={[25, 15]} />
            <shadowMaterial
              attach="material"
              side={BackSide}
              color="#000"
              opacity={0.2}
              transparent
            />
          </mesh>
        </Canvas>
      </div>
      <BattleInfo
        avatarSrc="/stemmy/p_head.png"
        orientation={Orientation.LEFT}
        totalHealth={3}
        totalEnergy={10}
        properties={[]}
        ref={PlayerBattleInfo}
      />
      <BattleInfo
        avatarSrc="/stemmy/e_head.png"
        orientation={Orientation.RIGHT}
        totalHealth={3}
        totalEnergy={10}
        properties={[]}
        ref={EnimyBattleInfo}
      />
      {/* =========================================== QUEST =========================================== */}

      {isFailedChallange !== FailType.None && (
        <BlackBackground className="z-20">
          <div className="absolute z-30 w-full h-full flex flex-col justify-center items-center">
            <InformingView>
              <div className="flex flex-col gap-3 my-2">
                <div className="font-bold text-game-lg">
                  {isFailedChallange === FailType.QuestFailed && (
                    <span>
                      {t('you-should-activate-stacking-to-proceed', {
                        ns: 'universal',
                      })}
                    </span>
                  )}
                  {isFailedChallange === FailType.IncorrectUsername && (
                    <span>
                      {t(
                        'it-looks-like-the-account-with-the-username-you-just-entered-doesnt-exist',
                        {ns: 'universal'},
                      )}
                    </span>
                  )}
                </div>
                <Button onClick={() => setIsFailedChallange(FailType.None)}>
                  {t('try-again-1', {ns: 'universal'})}
                </Button>
              </div>
            </InformingView>
          </div>
        </BlackBackground>
      )}

      <GamePopup
        prefixId={prefixId}
        id="test1"
        active={currectStage == 0}
        tempHidden={isQuestionsHidden}
        elements={[
          {
            id: 'task1',
            horizontalElement: (
              <span className="font-bold text-game-lg block text-center">
                {t('how-to-make-money-in-near')}
              </span>
            ),
            windowSize: WidnowSize.Big,
            buttonsLayout: ButtonsLayout.TWO_COLUMNS,

            externalbuttons: [
              {
                id: '1',
                title: t(
                  'earning-income-from-staking-like-interest-on-a-bank-account',
                ),
                clickDelay: 1000,
                isCoorrectAnswer: false,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () => onQuestionAnswered(false),
              },
              {
                id: '2',
                title: t('play-the-lottery'),
                clickDelay: 1000,
                isCoorrectAnswer: true,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () => onQuestionAnswered(true),
              },
              {
                id: '3',
                title: t('complete-tasks-in-games-and-on-special-platforms'),
                clickDelay: 1000,
                isCoorrectAnswer: false,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () => onQuestionAnswered(false),
              },
              {
                id: '4',
                title: t('changing-near-rates'),
                clickDelay: 1000,
                isCoorrectAnswer: false,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () => onQuestionAnswered(false),
              },
            ],
          },
          {
            id: 'task2',
            horizontalElement: (
              <span className="font-bold text-game-lg text-center block text-center">
                {t('choose-a-bad-way-to-get-near')}
              </span>
            ),
            windowSize: WidnowSize.Big,
            buttonsLayout: ButtonsLayout.TWO_COLUMNS,

            externalbuttons: [
              {
                id: '1',
                title: t('buy-from-a-card-in-the-here-app'),
                clickDelay: 1000,
                isCoorrectAnswer: false,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () =>
                  onQuestionAnswered(false, () => setCurrectStage(1)),
              },
              {
                id: '2',
                title: t('get-from-a-friend'),
                clickDelay: 1000,
                isCoorrectAnswer: false,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () =>
                  onQuestionAnswered(false, () => setCurrectStage(1)),
              },
              {
                id: '3',
                title: t('generate-on-my-phone'),
                clickDelay: 1000,
                isCoorrectAnswer: false,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () =>
                  onQuestionAnswered(false, () => setCurrectStage(1)),
              },
              {
                id: '4',
                title: t(
                  'exchange-near-for-another-cryptocurrency-in-the-here-app',
                ),
                clickDelay: 1000,
                isCoorrectAnswer: true,
                isNextOnClick: true,
                className: 'text-game-lg',
                onClick: () =>
                  onQuestionAnswered(true, () => setCurrectStage(1)),
              },
            ],
          },
        ]}
      />

      <GamePopup
        prefixId={prefixId}
        id="broll"
        active={currectStage == 1}
        tempHidden={isQuestionsHidden}
        onStart={() => {
          bgFightAudio.current?.stop();
          bgNormalAudio.current?.play();
        }}
        onFinish={() => setCurrectStage(2)}
        elements={[
          {
            id: '5AEDA6DB-87C3-4C84-87AE-D1A30720CE95',
            conversationImage: '/stemmy/e_head.png',
            title: t(
              'what-a-tough-little-guy-i-think-only-a-magic-attack-will-take-it-enchant-him-with-your-power',
            ),

            externalbuttons: [
              {
                id: 'next',
                title: t('cast-a-spell'),
                buttonStyle: ButtonStyle.Purple,
                className: 'text-game-xl',
                isNextOnClick: true,
              },
            ],
          },
        ]}
      />

      <GamePopup
        prefixId={prefixId}
        id="quest1"
        active={currectStage == 2}
        tempHidden={isQuestionsHidden}
        onFinish={() => setCurrectStage(3)}
        elements={[
          {
            id: 'quest1_intro',
            conversationImage: '/stemmy/e_head.png',
            title: t('unlock-your-own-staking'),
            text: t(
              'now-you-can-unlock-your-staking-if-you-have-1-000-and-activate-staking-it-will-turn-into-1-100-in-one-year-10-annualy',
            ),
            innerButtons: [
              {
                className: 'flex-1',
                id: 'next',
                title: t('next'),
                isNextOnClick: true,
              },
            ],
            buttonsAlignment: ButtonsAlignment.Horizontal,
          },
          {
            id: 'step1',
            conversationImage: '/stemmy/e_head.png',
            title: t('step-1-click-on-the-button-stake'),

            verticalElement: (
              <AndroidPhone image="/here/android/quest2/1.jpg" />
            ),
            innerButtons: [
              {
                id: 'back',
                title: t('back'),
                isBackOnClick: true,
              },
              {
                className: 'flex-1',
                id: 'next',
                title: t('next'),
                isNextOnClick: true,
              },
            ],
            windowSize: WidnowSize.Big,
            buttonsAlignment: ButtonsAlignment.Horizontal,
          },
          {
            id: 'step2',
            conversationImage: '/stemmy/e_head.png',
            title: t('step-2-hit-start-staking'),
            horizontalElement: (
              <form
                className="mt-2"
                onSubmit={handleSubmit(onCheckQuestSubmit)}
              >
                <div className="blockk">
                  {t('enter-your-near-username-below', {ns: 'universal'})}
                </div>
                <div className="flex w-full mt-2 gap-2">
                  <Input
                    className="flex-1"
                    registration={{register, errors}}
                    name="nearUserName"
                    options={{
                      required: t('please-enter-your-near-account', {
                        ns: 'universal',
                      }),
                    }}
                  />
                  <Button type="submit">{t('ok-1')}</Button>
                </div>
              </form>
            ),

            verticalElement: (
              <AndroidPhone image="/here/android/quest2/2.jpg" />
            ),
            innerButtons: [
              {
                id: 'back',
                title: t('back'),
                isBackOnClick: true,
              },
            ],
            windowSize: WidnowSize.Big,
          },
        ]}
      />
      <GamePopup
        prefixId={prefixId}
        id="fin"
        active={currectStage == 3}
        tempHidden={isQuestionsHidden}
        onFinish={() => switchScene(3)}
        elements={[
          {
            id: 'fin',
            conversationImage: '/stemmy/e_head.png',
            title: t('congratulations-youll-now-earn-10-a-year'),

            innerButtons: [
              {
                className: 'flex-1',
                id: 'next',
                title: t('get-a-reward'),
                isNextOnClick: true,
              },
            ],
            buttonsAlignment: ButtonsAlignment.Horizontal,
          },
        ]}
      />
    </div>
  );
};

export default HerePart2;
