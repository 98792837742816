import {Dialog} from './StemmyDialogsPage';

export const dialogs: Dialog[] = [
  {
    question: 'Nowadays knowledge is a cheatcode. Ready to quest?',
    answers: [
      {
        answer: 'Lets go!',
        answerId: '66C1B6CA-5F06-451E-8581-770CC3C40573',
      },
    ],
    onClicked: (answer: string) => console.log(`Clicked on: ${answer}`),
  },
  {
    question: "Let's learn the basics for a beginning. Show what you can",
    answers: [
      {
        answer: 'Start a fight',
        answerId: '23D811B6-BBB5-489E-B756-182010C2D0B3',
      },
    ],
    onClicked: (answer: string) => console.log(`Clicked on: ${answer}`),
  },
];

export const dialogs2: Dialog[] = [
  {
    question: 'Nice! Now i see that you are ready for a first quest.',
    answers: [
      {
        answer: 'Start Quest',
        answerId: '642D5D7D-363D-435B-AF23-629F22C426CB',
      },
    ],
    onClicked: (answer: string) => console.log(`Clicked on: ${answer}`),
  },
  {
    question: 'What reward do you want to get?',
    answers: [
      {
        answer: 'Sword',
        answerId: 'BBEA0607-195A-4C0D-907F-CE28492E8987',
      },
      {
        answer: 'NEAR tokens',
        answerId: '968478E1-FF43-492A-AA91-AB013EA82D9A',
      },
    ],
    onClicked: (answer: string) => console.log(`Clicked on: ${answer}`),
  },
];

export const dialogs4: Dialog[] = [
  {
    question:
      'Almost won! Quick - complete one more challenge to win the fight!',
    answers: [
      {
        answer: 'Start quest',
        answerId: '87E322A0-63EB-4487-BF39-2C8C26A7D203',
      },
    ],
    onClicked: (answer: string) => console.log(`Clicked on: ${answer}`),
  },
];

export const dialogs5: Dialog[] = [
  {
    question: 'I have one more quest for you.',
    answers: [
      {
        answer: 'I want to fight!',
        answerId: 'E85A0662-5DC1-46CB-81DD-E3B853541F90',
      },
      {
        answer: 'What is the quest?',
        answerId: '997C7027-EC99-4FC5-95CB-2838800D643E',
      },
    ],
    onClicked: (answer: string) => console.log(`Clicked on: ${answer}`),
  },
];

export const dialogs1Android: Dialog[] = [
  {
    question:
      'Hmm.. We dont have an android download link yet... But we have a HERE wallet simulator!',
    answers: [
      {
        answer: 'Lets try it',
        answerId: '847E9CB2-ADCB-4F67-AB03-1A92D86D0717',
      },
    ],
    onClicked: (answer: string) => console.log(`Clicked on: ${answer}`),
  },
];

export const dialogs7: Dialog[] = [
  {
    question:
      'You have completed all the tasks! Now you are ready to join a tournament!',
    answers: [
      {
        answer: 'Lets go!!',
        answerId: 'A36AF47A-762F-477D-A99E-51D15D0775A7',
      },
    ],
    onClicked: (answer: string) => console.log(`Clicked on: ${answer}`),
  },
];
